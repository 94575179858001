.hamIcon {
    width: 40px;
    height: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.hamBtn {
    margin-top: 40px;
}

.hamBtnFst {
    margin-top: 10px;
}

.hamModal .sheets__content-wrapper {
    overflow: unset !important;
}

.hamModelBody {
    height: calc(100vh - 72px);
    /* overflow-y: auto; */
}

.hamTitle {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    cursor: pointer;
}

.hamText {
    font-family: 'Noto IKEA Latin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    color: #000000;
    margin-top: 40px;
    letter-spacing: 0em;
    text-align: left;
}

.hamText:hover {
    font-weight: 700;
}

.hamTextActive {
    font-weight: 700;
    color: #0058A3;
}

.hamQcWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}