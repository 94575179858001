.scContainer {
    padding-left: 175px;
    padding-right: 175px;
    padding-bottom: 60px;
    margin-top: 60px;
    height: calc(100vh - 60px);
    overflow-y: scroll;
}

@media only screen and (max-width: 1080px) {
    .scContainer {
        padding-left: 96px;
        padding-right: 96px;
    }
}


.scContainer .scHeaderContainer {
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 80%;
    background-color: white;
    position: fixed;
    margin-left:-27px;
}

.scHeaderContainer .scLeftWrapper {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;
}

.scHeaderContainer .scRightWrapper {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
}

.scHeaderContainer .scProjectTitle {
    font-family: Noto IKEA Latin;
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -16px;
}

.scHeaderContainer .scBtTertiary>span {
    height: 48px;
    width: 48px;
}

.scDateContainer {
    margin-top: 92px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
}

.scDateWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
}

.scTitle {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
}

.scBody {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
}

.scLabel {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
}

.scWarningWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-top: 8px;
}

.scWarning {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
}

.scWarningBlue {
    color: white;
    background: #0058A3;
    border-radius: 999px;
    height: 16px;
    width: 16px;
}

.scWarningOrange {
    color: white;
    background: #F26A2F;
    border-radius: 999px;
    height: 16px;
    width: 16px;
}

.scLinkOut {
    color: #0058A3;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 16px;
}

.scMessageWrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.scAccordion {
    margin-top: 32px;
}

.scItemsSectionWrapper {
    margin-top: 32px;
}

.scTableClickable tr.ant-table-row.ant-table-row-level-0 {
    cursor: pointer;
}

.scTableNormal tr.ant-table-row.ant-table-row-level-0 {
    cursor: default;
}

.scTableCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
}

.scTableNameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scTableCellWrapper {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
}

.scCountWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.scComparisonCount {
    width: 40px;
}

.scTableCheckBox {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
    margin: 0px !important;
    flex-grow: 1;
}

.scTableCheckBox>span:nth-child(2) {
    padding-right: 5px;
}

.scTableCheckBox>span:nth-child(1) {
    margin-bottom: 4px;
}

.scItemsSectionWrapper .th.ant-table-cell:last-child {
    padding-left: 24px !important;
}

.scUnitItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.scUnitName {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #111111;
}

.scActivityDate {
    line-height: 26px;
}

.divider_vertical {
    height: 26px;
    margin: 0px 0px 0px 16px;
    border: 0;
    border-right: 1px solid #DFDFDF;
}

.scActivityLogWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.scAcceptedWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.scUnitsWrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.scDisabled {
    cursor: not-allowed;
    opacity: 0.8;
}

.scDisabled>span>input {
    cursor: not-allowed;
}

.sc-mb-24 {
    margin-bottom: 24px;
}

.removeItem {
    text-decoration: line-through;
    cursor: default;
    color: #484848;
}

.scTableNC {
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1;
    gap: 8px;
}

.scTableNCText {
    cursor: default;
}

.scEditNC {
    color: #0058A3;
}

.scCb {
    cursor: pointer;
    margin-right: 8px;
}

.scCbDisable {
    cursor: default;
}