.modal-body .scroll-indicator {
    height: 1.5rem;
    position: relative;
    width: 100%;
    outline: none;
    border: 0;
    padding: 0;
    background: transparent;
    display: flex !important;
    align-items: center;
    cursor: pointer;
}

.item-image {
    width: 100%;
    height: 280px !important;
    object-fit: cover;
}

.item-date {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 700;
}

.itemSubTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-body .accordion__content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px 16px;
}

.itemContWidth {
    width: 100%;
}

.projectItemPanel-subTitle-container {
    display: flex;
}

.sectionSubTitle {
    line-height: 20px;
}

.sectionBottom {
    margin-bottom: 15px;
}

.pipCustomNewsContainer {
    display: flex;
    flex-direction: column;
}

.pipCustomNewsDetailWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px;
    gap: 16px;
    align-items: center;
}

.pipSection {
    display: flex;
    flex-direction: column;
}

.pipLabel {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    color: #767676;
}

.pipValue {
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #000000;
}

.pipUrl>a {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #0058A3;
}

.pipCustomNewsDetailWrapper .ant-divider {
    height: unset;
    margin: 6px 8px;
    border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
}

.pipNoticeWrapper {
    display: flex;
    align-items: center;
}

.pipNoticeIcon {
    width: 16px;
    height: 16px;
    color: white;
    background: #0058a3;
    border-radius: 100px;
    margin-right: 8px;
}

.pipNoticeText {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.pipMidWrapper {
    flex-direction: column;
    gap: 24px;
}

.pipModelBody .carousel_slide {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DFDFDF;
}

.pipModelBody .carousel_slide>div:first-child {
    max-height: 280px;
    max-width: 100%;
    display: inline-block;
}