.modal-footer .btn__inner {
    height: 56px !important;
    font-size: 14px !important;
}

.item-title {
    margin-top: 20px;
    margin-bottom: 8px;
    line-height: 32px;
    font-weight: 700;
}

.modal-body .carousel-slide {
    height: 252px;

}

.modal-body .scroll-indicator {
    height: 1.5rem;
    position: relative;
    width: 100%;
    outline: none;
    border: 0;
    padding: 0;
    background: transparent;
    display: flex !important;
    align-items: center;
    cursor: pointer;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-date {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.itemSubTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-body .accordion__content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px 16px;
}

.itemContWidth {
    width: 100%;
}

.itemDetailsMobile-subTitle-container {
    display: flex;
}

.idmSectionSubTitle {
    line-height: 24px;
}

.idmDateWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0px;
}

.idmSectionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px;
    align-items: center;
}

.idmTopWrapper {
    gap: 0px;
}

.idmDivider {
    height: 40px;
}

.idmSection {
    display: flex;
    flex-direction: column;
}

.idmLabel {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    color: #767676;
}

.idmValue {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #000000;
}

.idmUrl>a {
    font-family: Noto IKEA Latin;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #0058A3;
}