.modal-footer .btn__inner {
    height: 56px !important;
    font-size: 14px !important;
}

.itemDetails-item-title {
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
}

.itemDetails-item-subTitle-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.itemDetails-item-subTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
}

.modal-body .carousel-slide {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DFDFDF;
}

.modal-body .scroll-indicator {
    height: 1.5rem;
    position: relative;
    width: 100%;
    outline: none;
    border: 0;
    padding: 0;
    background: transparent;
    display: flex !important;
    align-items: center;
    cursor: pointer;
}

.itemDetails-item-image {
    width: 100%;
    height: 85%;
    object-fit: contain;
}

.itemDetails-item-date {
    font-family: Noto IKEA Latin;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.itemDetails-measure-title {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.itemDetails-measure-value {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.itemSubTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-body .accordion__content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px 16px;
}

.itemContWidth {
    width: 100%;
}

.itemDetailsContentWrapper {
    display: flex;
    gap: 32px;
    margin-top: 32px;
}

.leftContainer {
    flex-basis: 80%;
    padding: 0px 92px;
}

.leftContainerItemDetails {
    flex-basis: 80%;
    padding: 0px 3px;
}

.rightContainer {
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.itemDetails-theatre {
    border-radius: 8px !important;
    left: 10% !important;
    top: 48px !important;
    height: calc(100vh - 96px) !important;
    width: 80% !important;
}

.btAddComparison {
    margin-bottom: 16px;
}

.tDeadline {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.modal-body {
    padding-top: 0px;
}

.materialWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
}

.itemDetailSectionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px;
}

.idTopWrapper {
    gap: 20px;
}

.idTopWrapper .ant-divider {
    height: unset;
    margin: 6px 12px;
    border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
}

.noticeContainer {
    display: flex;
    align-items: center;
}

.cuContainer {
    display: flex;
    flex-direction: column;
}

.cuoNoticeIcon {
    width: 16px;
    height: 16px;
    color: white;
    background: #0058a3;
    border-radius: 100px;
    margin-right: 8px;
}

.noticeText {
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.idBtDownload {
    width: 184px;
    margin-bottom: 15px;
}